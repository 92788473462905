import React from "react";
import "./About.css";

function About() {
  return (
    <div className="section about_wrapper">
      <div className="headline">about me</div>
      <div className="separator"></div>
      <div className="title text-h2-5">
        Hi, I'm Phuoc, a tireless seeker of knowledge, aspiring developer, and
        also, coincidentally, a cloud engineer.
      </div>
      <a
        className="button"
        href="/PHUOC_PHAN_RESUME.pdf"
        download="phuoc_phan_resume.pdf"
      >
        Download my resume
      </a>
    </div>
  );
}

export default About;
