import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer_section">
      <div className="separator"></div>
      <div className="small-line">
        <div className="left">@{new Date().getFullYear()}</div>

        <div className="right">
          <ul className="socials">
            <li>
              <a href="https://www.linkedin.com/in/phuoc-phan/" target="_blank">
                linkedin
              </a>
            </li>
            <li>
              <a href="https://github.com/pphan93" target="_blank">
                github
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
