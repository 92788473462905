import React from "react";
import "./Skills.css";

function Skills() {
  return (
    <>
      <div className="skills_section skills">
        <div className="skills_headline">skills</div>
        <div className="separator text-top"></div>
      </div>
      <div className="m-scroll">
        <div className="m-scroll__title">
          <div>
            <h1>
              <a href="https://nodejs.org/en/">NODEJS,</a>
              <a href="https://reactjs.org/">REACTJS</a> &amp;
              <a href="https://www.javascript.com/">JAVASCRIPT </a>&nbsp;
            </h1>
            <h1>
              <a href="https://nodejs.org/en/">NODEJS,</a>
              <a href="https://reactjs.org/">REACTJS</a> &amp;
              <a href="https://www.javascript.com/">JAVASCRIPT </a>&nbsp;
            </h1>
          </div>
        </div>
      </div>
      <div className="m-scroll">
        <div className="m-scroll__title_left">
          <div>
            <h1>
              <a href="https://www.microsoft.com/en-us/sql-server/sql-server-2019">
                MSSQL,
              </a>
              <a href="https://www.mongodb.com/">MONGODB</a> &amp;
              <a href="https://developer.mozilla.org/en-US/docs/Glossary/HTML5">
                HTML5
              </a>
              &nbsp;
            </h1>
            <h1>
              <a href="https://www.microsoft.com/en-us/sql-server/sql-server-2019">
                MSSQL,
              </a>
              <a href="https://www.mongodb.com/">MONGODB</a> &amp;
              <a href="https://developer.mozilla.org/en-US/docs/Glossary/HTML5">
                HTML5
              </a>
              &nbsp;
            </h1>
          </div>
        </div>
      </div>
      <div className="skills_section">
        <div className="separator text-top"></div>
      </div>{" "}
    </>
  );
}

export default Skills;
