import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div className="contact_section">
      <div className="contact_title">
        <h3
          data-scroll="true"
          data-scroll-class="in-view"
          className="home__title home__contact-title home__title--3 row flex-column in-view"
          style={{ perspective: "200px", color: "rgb(230, 230, 230)" }}
        >
          <span className="col-auto align-self-start">GET IN</span>
          <span className="col-auto align-self-end">TOUCH</span>
        </h3>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div
            data-scroll="true"
            data-scroll-class="in-view"
            className="home__cwrap home__contact-text in-view"
          >
            <div className="home__contact">
              <span>
                If you have a general OR
                <i style={{ color: "#222222" }}>PROJECT</i>
                <i style={{ color: "#222222" }}>enquiry</i>,
              </span>
              <span> please drop me an </span>
              <span className="home__contact-email">
                <u>
                  <a href="mailto:phuocphan12@gmail.com">email</a>
                </u>
                <img
                  className="home__intro-gif home__intro-gif--1"
                  src="/static/test-Y34-fdfe125168d07904bb7302d9f0a9e344.jpg"
                  alt=""
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
