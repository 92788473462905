import React from "react";
import "./Hero.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const particleOptions = {
  fullScreen: { enable: false },
  background: {
    color: {
      value: "black",
    },
  },
  fpsLimit: 120,
  interactivity: {
    events: {
      onClick: { enable: true, mode: "push" },
      onHover: {
        enable: true,
        mode: "repulse",
      },
      resize: true,
    },
    modes: {
      push: { quantity: 4 },
      repulse: { distance: 200, duration: 0.4 },
    },
  },
  particles: {
    color: { value: "#ffffff" },
    move: {
      bounce: false,
      direction: "none",
      enable: true,
      outModes: "out",
      random: false,
      speed: 2,
      straight: false,
    },
    number: { density: { enable: true, area: 800 }, value: 120 },
    opacity: {
      value: 0.5,
    },
    links: {
      enable: true,
    },
    size: {
      value: 1,
    },
  },
  detectRetina: true,
};

function Hero() {
  const particlesInit = async (main) => {
    // console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    // console.log(container);
  };
  return (
    <section className="hero">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particleOptions}
      />
      <div className="text">
        <div className="line first_line">
          <span>Full Stack</span>
        </div>
        <div className="line second_line important_word">
          <span>Developer</span> <span className="and">and</span>
        </div>

        <div className="line third_line">
          <span>Cloud Engineer</span>
        </div>
      </div>
      <div className="scroll-indicator">
        <div className="dots"></div>
      </div>
    </section>
  );
}

export default Hero;
