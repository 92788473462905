import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

import "./Projects.css";

const projects = [
  {
    title: "Pursuit",
    img: "https://ingitaph.sirv.com/pphan.ca/pursuit.mp4",
    url: "https://github.com/pphan93/pursuit",
  },
  {
    title: "FastShion",
    img: "https://ingitaph.sirv.com/pphan.ca/fastshion.mp4",
    url: "https://github.com/pphan93/FastShion",
  },
];

function Projects() {
  const [ref, inView] = useInView();

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
    opacity: 0,
    id: "",
  });

  const [videoURL, setVideoUrl] = useState("");

  const onMouseOverHandler = (e, id) => {
    // console.log(e);
    setMousePosition({ x: e.clientX, y: e.clientY, id: id });

    // let x = e.clientX;
    // let y = e.clientY;
  };

  const onHoverHandler = (e) => {
    // setVideoUrl(true);

    // console.log(mousePosition);
    setVideoUrl(projects[e.target.id].img);
  };

  return (
    <div className="project_section" ref={ref}>
      <div className="headline">
        <div className="container">
          <h1>
            <div className={inView ? "animation" : undefined}>
              <div className={inView ? "animation-left" : undefined}>
                <span>projects</span>
              </div>
              <div className={inView ? "animation-right" : undefined}>
                <span>projects</span>
              </div>
            </div>
          </h1>
        </div>
      </div>
      <div className="separator"></div>
      <div className="projects_container">
        {projects.map((project, index) => {
          return (
            <div key={index}>
              <div className="project_content">
                <div className="index">
                  ({index + 1 < 10 ? `0${index + 1}` : index + 1})
                </div>
                <a
                  className="title text-h1"
                  id={index}
                  href={project.url}
                  onMouseEnter={(e) => onHoverHandler(e, index)}
                  onMouseMove={(e) => onMouseOverHandler(e, index)}
                  onMouseLeave={() => {
                    setMousePosition({
                      ...mousePosition,
                      id: "",
                    });
                  }}
                >
                  {project.title}
                </a>
              </div>
              <div className="hover-images-wrapper">
                <div
                  className={`hover-images ${
                    mousePosition.id === index ? "" : "invisble"
                  }`}
                  style={{
                    transform: `${
                      mousePosition.id === index
                        ? `translate3d(${mousePosition.x}px, ${mousePosition.y}px, 0px)`
                        : ""
                    }`,
                  }}
                >
                  <video
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    playsInline={true}
                    // crossOrigin="true"
                  >
                    <source src={project.img} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  {/* <img src={project.img} alt="" /> */}
                </div>
              </div>
              <div className="separator"> </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Projects;
